// Default and angular components/services
import { NgModule } from '@angular/core';
import { RouterModule, Routes, Route } from '@angular/router';

// Services
import { AuthService } from './services/auth/auth.service';
import { AuthGuard } from './services/auth/auth-guard.service';

// Components
import { ReportComponent } from './components/report/report.component';
import { VaultComponent } from './components/vault/vault.component';
import { MediaComponent } from './components/media/media.component';
import { LoginComponent } from './components/login/login.component';
import { HomeComponent } from './components/home/home.component';
import { MediaDetailComponent } from './components/mediadetail/mediadetail.component';
import { NotFoundComponent } from './components/notfound/notfound.component';
import { AccountSettingsComponent } from './components/account/settings/accountsettings.component';

import { SpotUploadComponent } from './components/media/spotupload/spotupload.component';
import { ForgotPasswordComponent } from "./components/account/forgotpassword/forgotpassword.component";
import { NotificationComponent } from "./components/notifications/notification.component";

import { OrdersComponent } from './components/orders/order-home/orders.component';
import { CreateOrderComponent } from './components/orders/order-create/createorder.component';
import { OrderDetailComponent } from "./components/orders/order-detail/orderdetail.component";
import { ClientComponent } from "./components/admin/client/client.component";
import { CreateClientComponent } from "./components/admin/client/create-client/create-client.component";
import { BrandComponent } from "./components/admin/brand/brand.component";
import { CreateBrandComponent } from "./components/admin/brand/create-brand/create-brand.component";
import { LoginGuard } from "./services/auth/login-guard.service";
import { OrderUploadComponent } from "./components/orders/order-upload/orderupload.component";
import { ClientDetailComponent } from "./components/admin/client/clientdetail/client-detail.component";
import { ViewEditBrandComponent } from "./components/admin/brand/viewedit-brand/viewedit-brand.component";
import { UserComponent } from "./components/admin/user/user.component";
import { CreateUserComponent } from "./components/admin/user/create-user/create-user.component";
import { UserDetailComponent } from "./components/admin/user/user-detail/user-detail.component";
import { DestinationComponent } from "./components/admin/destination/destination.component";
import { CreateDestinationComponent } from "./components/admin/destination/create-destination/create-destination.component";
import { DestinationDetailComponent } from "./components/admin/destination/destination-detail/destination-detail.component";
import { CanDeactivateCreateOrder, CanDeactivateOrderDetail, CanDeactivateOrderTranscodes } from './services/core/caneactivateuard.service';
import { QuickLinkDetailComponent } from './components/quicklinkdetail/quicklinkdetail.component';
import { OrderDebugComponent } from './components/orders/order-debug/orderdebug.component';
import { CreateNewPasswordComponent } from './components/account/createpassword/createnewpassword.component';
import { ChangePasswordComponent } from './components/account/createpassword/changepassword/changepassword.component';
import { ITOpsComponent } from './components/itOps/itOps.component';
import { SpotDownloadComponent } from './components/media/spotdownload/spotdownload.component';
import { DistributionComponent } from './components/distribution/distribution.component';
import { DistributionMediaDownloadComponent } from './components/distribution/distributionmediadownload/distributionmediadownload.component';
import { TMCDownloadComponent } from './components/distribution/tmcdownload/tmcdownload.component';
import { ProjectComponent } from './components/project/project.component';
import { TranscodeAssetsComponent } from './components/transcodeassets/transcodeassets.component';
import { OrderTranscodesComponent } from './components/transcodeassets/ordertranscodes/ordertranscodes.component';
import { TranscodeUserListsComponent } from './components/transcodeassets/transcodeuserlists/transcodeuserlists.component';
import { ProfileSelectionComponent } from './components/profileselection/profileselection.component';
import { RightsManagementComponent } from './components/rightsmangement/rightsmanagement.component';
import { VaultCreateComponent } from './components/vault/vault-create/vault-create.component';
import { VaultAssetDetailComponent } from './components/vault/vault-asset-detail/vault-asset-detail.component';
import { TaggerHomeComponent } from './components/tagger-home/tagger-home.component';
import { TaggerFormComponent } from './components/tagger-home/tagger-form/tagger-form.component';
import { CreativeTagsComponent } from './components/tagger-home/creative-tags/creative-tags.component';
import { GroupsComponent } from './components/tagger-home/groups/groups.component';
import { LocationsAndUsersComponent } from './components/tagger-home/locations-and-users/locations-and-users.component';

const routes: Route[] = [
  { path: '', redirectTo: 'login', pathMatch: 'full' },

  { path: 'itOps', component: ITOpsComponent, canActivate: [AuthGuard], data: { roles: ['IT - Dev'] } },
  { path: 'profileselection', component: ProfileSelectionComponent, data: { showNav: false } },
  { path: 'home', component: HomeComponent, canActivate: [AuthGuard] },
  { path: 'user/settings', component: AccountSettingsComponent, canActivate: [AuthGuard] },
  { path: 'reports', component: ReportComponent, canActivate: [AuthGuard], data: { roles: ['Reporting'] } },
  { path: 'vault', component: VaultComponent, canActivate: [AuthGuard], data: { roles: ['Vault - Viewer'] } },
  { path: 'assets', component: MediaComponent, canActivate: [AuthGuard], data: { roles: ['Asset'] } },
  { path: 'user/notifications', component: NotificationComponent, canActivate: [AuthGuard] },
  { path: 'admin/clients/create', component: CreateClientComponent, canActivate: [AuthGuard], data: { roles: ['Admin - Client Services'] } },
  { path: 'admin/clients', component: ClientComponent, canActivate: [AuthGuard], data: { roles: ['Admin - Accounting', 'Admin - View', 'Admin - Client Services'] } },
  { path: 'admin/clients/detail/:clientGuid', component: ClientDetailComponent, canActivate: [AuthGuard], data: { roles: ['Admin - View', 'Admin - Accounting', 'Admin - Client Services'] } },
  { path: 'admin/brands', component: BrandComponent, canActivate: [AuthGuard], data: { roles: ['Admin - View', 'Admin - Accounting', 'Admin - Client Services'] } },
  { path: 'admin/users', component: UserComponent, canActivate: [AuthGuard], data: { roles: ['Admin - View', 'Admin - Client', 'Admin - Client Services', 'Admin - Systems'] } },
  { path: 'admin/users/createprofile/:userType/:userName', component: CreateUserComponent, canActivate: [AuthGuard], data: { roles: ['Admin - Client Services', 'Admin - Systems'] } },
  { path: 'admin/users/create/:userType', component: CreateUserComponent, canActivate: [AuthGuard], data: { roles: ['Admin - Client Services', 'Admin - Client', 'Admin - Systems'] } },
  { path: 'admin/users/detail/:userProfileGuid', component: UserDetailComponent, canActivate: [AuthGuard], data: { roles: ['Admin - View', 'Admin - Client Services', 'Admin - Client', 'Admin - Systems'] } },
  { path: 'admin/destinations', component: DestinationComponent, canActivate: [AuthGuard], data: { roles: ['Admin - Systems', 'Admin - Client Services'] } },
  { path: 'admin/destinations/create', component: CreateDestinationComponent, canActivate: [AuthGuard], data: { roles: ['Admin - Systems', 'Admin - Client Services'] } },
  { path: 'admin/destinations/detail/:destinationGuid', component: DestinationDetailComponent, canActivate: [AuthGuard], data: { roles: ['Admin - Systems', 'Admin - Client Services'] } },
  { path: 'admin/brands/create', component: CreateBrandComponent, canActivate: [AuthGuard], data: { roles: ['Admin - Accounting', 'Admin - Client Services'] } },
  { path: 'admin/brands/detail/:brandGuid', component: ViewEditBrandComponent, canActivate: [AuthGuard], data: { roles: ['Admin - View', 'Admin - Accounting', 'Admin - Client Services'] } },
  { path: 'assets/detail/:spotFileGuid', component: MediaDetailComponent, canActivate: [AuthGuard], data: { roles: ['Asset'] } },
  { path: 'assets/upload', component: SpotUploadComponent, canActivate: [AuthGuard], data: { roles: ['Asset - Provider'] } },
  { path: 'assets/download', component: SpotDownloadComponent, canActivate: [AuthGuard], data: { roles: ['Asset - Download'] } },
  {
    path: 'assets/quicklink/:quickLinkGuid', component: QuickLinkDetailComponent, canActivate: [AuthGuard], data: {
      roles: ['Order - Builder', 'Order - Status Editor']
    }
  },
  { path: 'distribution', component: DistributionComponent, canActivate: [AuthGuard], data: { roles: ['Distribution - Media Viewer'] } },
  { path: 'distribution/assets/download', component: DistributionMediaDownloadComponent, canActivate: [AuthGuard], data: { roles: ['Distribution - Media Viewer'] } },
  { path: 'distribution/download/tmc', component: TMCDownloadComponent, canActivate: [AuthGuard], data: { roles: ['Distribution - Media Viewer'] } },
  { path: 'project', component: ProjectComponent, canActivate: [AuthGuard], data: { roles: ['Project'] } },
  { path: 'login/forgotpassword', component: ForgotPasswordComponent, data: { showNav: false } },
  { path: 'login/updatepassword', component: ChangePasswordComponent, data: { showNav: false } },
  { path: 'login', component: LoginComponent, data: { showNav: false } },
  { path: 'createnewpassword/:userName/:resetToken', component: CreateNewPasswordComponent, data: { showNav: false } },
  { path: 'orders', component: OrdersComponent, canActivate: [AuthGuard], data: { roles: ['Order'] } },
  { path: 'orders/create', component: CreateOrderComponent, canActivate: [AuthGuard], canDeactivate: [CanDeactivateCreateOrder],  data: { roles: ['Order - Builder'] } },
  { path: 'orders/draft/:orderDraftGuid', component: CreateOrderComponent, canActivate: [AuthGuard], data: { roles: ['Order - Builder'] } },
  { path: 'orders/uploaded/:uploadedOrderGuid', component: CreateOrderComponent, canActivate: [AuthGuard], data: { roles: ['Order - Builder'] } },
  { path: 'orders/detail/:orderGuid', component: OrderDetailComponent, canActivate: [AuthGuard], canDeactivate: [CanDeactivateOrderDetail], data: { roles: ['Order'] } },
  { path: 'orders/trace/:orderGuid', component: OrderDebugComponent, canActivate: [AuthGuard], data: { roles: ['IT - Dev'] } },
  { path: 'orders/upload', component: OrderUploadComponent, canActivate: [AuthGuard], data: { roles: ['Order - Builder'] } },
  { path: 'assets/transcodefiles', component: TranscodeAssetsComponent, canActivate: [AuthGuard], data: { roles: ['Asset - Transcoder'] } },
  { path: 'assets/transcodeorderlists/:listType', component: TranscodeUserListsComponent, canActivate: [AuthGuard], data: { roles: ['Asset - Transcoder'] } },
  { path: 'assets/transcode', component: OrderTranscodesComponent, canActivate: [AuthGuard], canDeactivate: [CanDeactivateOrderTranscodes], data: { roles: ['Asset - Transcoder'] } },
  { path: 'assets/talentRights', component: RightsManagementComponent, canActivate: [AuthGuard], data: { roles: ['Asset - RightsManagement'] } },
  { path: 'vault/create', component: VaultCreateComponent, canActivate: [AuthGuard], data: { roles: ['Vault - Builder'] } },
  { path: 'vault/detail', component: VaultAssetDetailComponent, canActivate: [AuthGuard], data: { roles: ['Vault - Builder'] } },
  { path: 'tagger', component: TaggerHomeComponent, canActivate: [AuthGuard], data: { roles: ['Tagger'] } },
  { path: 'tagger/ad-creator', component: TaggerFormComponent, canActivate: [AuthGuard], data: { roles: ['Tagger - Local'] } },
  { path: 'tagger/creative-tags', component: CreativeTagsComponent, canActivate: [AuthGuard], data: { roles: ['Tagger - Local'] } },
  { path: 'tagger/groups', component: GroupsComponent },
  { path: 'tagger/locations-users', component: LocationsAndUsersComponent },
  { path: 'tagger/download', component: SpotDownloadComponent, canActivate: [AuthGuard], data: { roles: ['Tagger - Local'] } },
  { path: '**', redirectTo: 'login', pathMatch: 'full', data: { showNav: false } },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes)
  ],
  exports: [
    RouterModule
  ],
  providers: [
    AuthService, AuthGuard
  ]
})
export class AppRoutingModule { }

//{ path: 'login', loadChildren: './components/login/login.module#LoginModule' },
//{ path: 'home', loadChildren: './components/home/home.module#HomeModule' },
//{
//  path: '**',
//    redirectTo: 'home',
//      pathMatch: 'full'
//},
