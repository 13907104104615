import { Component, Input, OnInit, QueryList, ViewChild, ViewChildren } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { PopoverDirective } from 'ngx-bootstrap/popover';
import { Constants } from '../../../configurations/constants';
import { VaultAssetStages } from '../../../configurations/enums/enums';
import { Vaultassetnotes, VaultAssetPopupGridModel, VaultDigitalAssetDetail, VaultDigitalAssetEditRequest, VaultFile, VaultStatusChangeRequest } from '../../../models/vault/vault-create.model';
import { AlertService, MessageSeverity } from '../../../services/core/alert.service';
import { ConfigService } from '../../../services/core/config.service';
import { GlobalService } from '../../../services/core/global.service';
import { Utilities } from '../../../services/core/utilities';
import { DownloadMediaService } from '../../../services/media/media-download.service';
import { VaultService } from '../../../services/vault/vault.service';
import { AddVaultAssetAttachmentPopupComponent } from '../../popups/add-vault-asset-attachment-popup/add-vault-asset-attachment-popup.component';
import { AddVaultAssetNoteComponent } from '../../popups/add-vault-asset-note/add-vault-asset-note.component';
import { VaultAssetPopupComponent } from '../../popups/vault-asset-popup/vault-asset-popup.component';

@Component({
  selector: 'app-vault-digital-asset-detail',
  templateUrl: './vault-digital-asset-detail.component.html',
  styleUrls: ['./vault-digital-asset-detail.component.css']
})
export class VaultDigitalAssetDetailComponent implements OnInit {

  @Input('vaultDigitalAssetLineItemId') vaultDigitalAssetLineItemId: number;

  @ViewChildren('pop') pop: QueryList<PopoverDirective>;
  @ViewChild('clearVaultpop', { static: false }) clearVaultpop: PopoverDirective;

  public attachments: Array<VaultFile>;
  public notes: Array<Vaultassetnotes>;
  public vaultAssetDetail: VaultDigitalAssetDetail;
  public vaultAssetRequest: FormGroup;
  public IsEditable: boolean = false;
  public bsModalRef: BsModalRef;
  public clientDataSource: any = [];
  public brandDataSource: any = [];
  public productDataSource: any = [];
  public assets: Array<any>;

  constructor(private vaultService: VaultService,
    public util: Utilities,
    public modalService: BsModalService,
    private alertService: AlertService,
    private fb: FormBuilder,
    private configService: ConfigService,
    private ds: DownloadMediaService,
    private router: Router,
    private gs: GlobalService) { }

  ngOnInit() {
    this.getVaultDigitalAssetDetail();
  }

  private getVaultDigitalAssetDetail() {

    Promise.resolve(null).then(() => this.alertService.ShowLoader(true));

    this.vaultService.getVaultDigitalAssetDetails(this.vaultDigitalAssetLineItemId).subscribe((res: any) => {
      console.log(res);
      if (res.isSuccess == true) {
        this.vaultAssetDetail = res.result as VaultDigitalAssetDetail;
        console.log(this.vaultAssetDetail);

        this.attachments = this.vaultAssetDetail.vaultFiles;
        this.notes = this.vaultAssetDetail.vaultAssetNotes;

        this.createDatasources();

        this.vaultAssetRequest = this.vaultAssetForm;
      }
      else {
        this.util.handleIsNotSuccess(res.errors);
      }

      this.alertService.ShowLoader(false);
    },
      error => {
        this.util.handleError(error);
        this.alertService.ShowLoader(false);
      });
  }

  public get vaultAssetForm() {
    return this.fb.group({
      assetIdentifier: new FormControl(this.vaultAssetDetail.assetIdentifier, Validators.required),
      title: new FormControl(this.vaultAssetDetail.title, Validators.required),
      length: new FormControl(this.vaultAssetDetail.length, Validators.required),
      legacyMasterId: new FormControl(this.vaultAssetDetail.legacyMasterId, Validators.required),
      legacyVtmNumber: new FormControl(this.vaultAssetDetail.legacyVtmNumber, Validators.required),
      assetDescription: new FormControl(this.vaultAssetDetail.assetDescription, Validators.required),
      createdby: new FormControl(this.vaultAssetDetail.createdby),
      clientId: new FormControl(this.vaultAssetDetail.vaultRequestClientId),
      brandId: new FormControl(this.vaultAssetDetail.vaultRequestBrandId),
      productId: new FormControl(this.vaultAssetDetail.vaultRequestProductId),
    });
  }

  public createDatasources() {
    this.clientDataSource = [{ id: this.vaultAssetDetail.vaultRequestClientId, name: this.vaultAssetDetail.vaultRequestClient }];
    this.productDataSource = [{ id: this.vaultAssetDetail.vaultRequestProductId, name: this.vaultAssetDetail.vaultRequestProduct }];
    this.brandDataSource = [{ id: this.vaultAssetDetail.vaultRequestBrandId, name: this.vaultAssetDetail.vaultRequestBrand }];
  }

  public onAddNewNote() {
    const initialState = {
      vaultDigitalAssetLineItemId: this.vaultAssetDetail.vaultDigitalAssetLineItemId
    };
    this.bsModalRef = this.modalService.show(AddVaultAssetNoteComponent, this.util.getModalComponentOptions(initialState, false, false, true));
    this.bsModalRef.content.onSaveVaultAssetNote.subscribe((result: Vaultassetnotes) => {
      console.log(result);
      if (result != null && result != undefined) {
        this.vaultAssetDetail.vaultAssetNotes.push(result);
        this.notes = this.vaultAssetDetail.vaultAssetNotes
      }
    });
  }

  public onAddNewAttachment() {
    var initialState = {
      vaultDigitalAssetLineItemId: this.vaultAssetDetail.vaultDigitalAssetLineItemId,
      vaultDigitalAssetLineItemGuid: this.vaultAssetDetail.vaultDigitalAssetLineItemGuid,
      isVaultDigitalAsset: true,
      fileType: "Attachment"
    };

    this.bsModalRef = this.modalService.show(AddVaultAssetAttachmentPopupComponent, this.util.getModalComponentOptions(initialState, false, true, false));
    this.bsModalRef.content.onSelectAttachmentVault.subscribe((result: VaultFile) => {
      console.log(result);
      if (result != null && result != undefined) {
        this.vaultAssetDetail.vaultFiles.push(result);
        this.attachments = this.vaultAssetDetail.vaultFiles
      }
    });
  }

  public onDownloadFile(vaultFileGuid, fileName) {
    if (vaultFileGuid == null || vaultFileGuid == '00000000-0000-0000-0000-000000000000' || vaultFileGuid == '')
      return;

    this.vaultService.downloadVaultFile(vaultFileGuid)
      .subscribe((res: any) => {
        if (res.isSuccess == true) {
          var presignedUrl = res.result;
          this.util.downloadFile(this.util.getFileUrl(presignedUrl)).subscribe(
            fileData => {
              var a = document.createElement("a");
              document.body.appendChild(a);
              a.href = window.URL.createObjectURL(fileData);
              a.download = fileName;
              a.click();
            });
        }
        else {
          this.util.handleIsNotSuccess(res.errors);
        }
      },
        error => {
          this.util.handleError(error);
        });
  }

  public onAttDelete(fileName: string, vaultFileGuid: string) {

    this.vaultService.deleteVaultFile(vaultFileGuid).subscribe((res: any) => {
      if (res.isSuccess == true) {
        var attToDeleteIndex = this.attachments.findIndex(t => t.vaultFileGuid == vaultFileGuid);

        if (attToDeleteIndex >= 0)
          this.attachments.splice(attToDeleteIndex, 1);

        this.alertService.showMessage("SUCCESSS", fileName + ' deleted successfully', MessageSeverity.success, true);
      }
      else {
        this.util.handleIsNotSuccess(res.errors);
      }
    },
      error => {
        this.util.handleError(error);
      });

    this.onHidePop();
  }

  public onHidePop() {
    this.pop.forEach(p => {
      p.hide();
    });
  }

  public editClicked() {
    this.IsEditable = true;
    this.vaultAssetRequest = this.vaultAssetForm;
  }

  public clearSavedData(): void {
    this.IsEditable = false;
    this.onHideClearVaultPop();
  }

  public onHideClearVaultPop() {
    this.clearVaultpop.hide();
  }

  public onSubmitVaultAsset({ value, valid }: { value: VaultDigitalAssetEditRequest, valid: boolean }) {
    value.vaultDigitalAssetLineItemId = this.vaultAssetDetail.vaultDigitalAssetLineItemId;
    value.vaultDigitalAssetLineItemGuid = this.vaultAssetDetail.vaultDigitalAssetLineItemGuid;
    console.log(value);
    if (!valid) {
      return;
    }

    this.alertService.ShowLoader(true);

    this.vaultService.updateVaultDigitalAsset(value).subscribe((res: any) => {
      console.log(res);
      if (res.isSuccess == true) {
        this.alertService.ShowLoader(false);
        this.alertService.showMessage("Success", Constants.success_upadate_asset_digital, MessageSeverity.success, true);
        this.vaultAssetDetail = res.result as VaultDigitalAssetDetail;

        this.attachments = this.vaultAssetDetail.vaultFiles;
        this.notes = this.vaultAssetDetail.vaultAssetNotes;

        this.IsEditable = false;

        this.gs.vaultDigitalAssetEdited();
      } else {
        this.alertService.ShowLoader(false);
        this.alertService.showMessage("Failed", Constants.failed_upadate_asset_digital, MessageSeverity.error, true);
      }
    }, error => {
      this.util.handleError(error);
      this.alertService.ShowLoader(false);
    });
  }

  public createGridModel() {
    this.assets = new Array<any>();
    var vaultAsset = new VaultAssetPopupGridModel();
    vaultAsset.vaultAssetId = this.vaultAssetDetail.vaultDigitalAssetLineItemId;
    vaultAsset.assetIdentifier = this.vaultAssetDetail.assetIdentifier;
    vaultAsset.client = this.vaultAssetDetail.vaultRequestClient;
    vaultAsset.brand = this.vaultAssetDetail.vaultRequestBrand;
    vaultAsset.product = this.vaultAssetDetail.vaultRequestProduct;
    this.assets.push(vaultAsset);
  }

  public stagingClicked() {
    this.createGridModel();

    var initialState = {
      selectedAssets: this.assets,
      source: VaultAssetStages.staging,
      isVaultDigitalAsset: true
    };
    console.log(initialState);
    this.bsModalRef = this.modalService.show(VaultAssetPopupComponent, this.util.getModalComponentOptions(initialState, false, false, true));

    this.bsModalRef.content.onVaultAssetStatusUpdated.subscribe(result => {
      console.log(result);
      if (result == true) {
        this.getVaultDigitalAssetDetail();
      }
    });
  }

  public backtoClicked() {
    console.log(this.vaultAssetDetail.vaultDigitalAssetLineItemId);
    var request = new VaultStatusChangeRequest();
    request.IsVaultDigitalAsset = true;
    request.VaultAssetId = this.vaultAssetDetail.vaultDigitalAssetLineItemId;

    this.alertService.ShowLoader(true);
    this.vaultService.updateVaultAssetStatusVault(request).subscribe((res: any) => {
      this.alertService.ShowLoader(false);
      if (res.isSuccess == true) {
        this.alertService.showMessage("SUCCESSS", Constants.assetStatusStagingUpdated, MessageSeverity.success, true);
        this.getVaultDigitalAssetDetail();
      } else {
        this.alertService.showMessage("ERROR", Constants.assetStatusUpdatedFail, MessageSeverity.error, true);
      }
    });
  }

}
