
<div class="d-flex justify-content-between" *ngIf="this.vaultAssetDetail">
  <div>
    <label class="tylie-badge tylie-badge--error" *ngIf="this.vaultAssetDetail.isLoggedOut == true">Logged out</label>
    <label class="tylie-badge tylie-badge--error" *ngIf="this.vaultAssetDetail.isStagging == true">Staging</label>
    <label class="tylie-badge tylie-badge--error" *ngIf="this.vaultAssetDetail.isRemoved == true">Removed</label>
    <label class="tylie-badge tylie-badge--success" *ngIf="this.vaultAssetDetail.isInVault == true">In Vault</label>
    <label class="tylie-badge tylie-badge--error" *ngIf="this.vaultAssetDetail.isDestroyed == true">Destroyed</label>
  </div>
  <div class="d-flex">
    <button type="button" class="tylie-button tylie-button--xs tylie-button--icon ms-3" (click)="stagingClicked()" *ngIf="this.vaultAssetDetail.isInVault == true">
      <span class="tylie-button__icon"><icon-settings color="#fff"></icon-settings></span>
      <span class="tylie-button__text">Staging</span>
    </button>
    <button type="button" class="tylie-button tylie-button--xs tylie-button--icon ms-3" (click)="backtoClicked()" *ngIf="this.vaultAssetDetail.isStagging == true">
      <span class="tylie-button__icon"></span>
      <span class="tylie-button__text">Back to Vault</span>
    </button>
    <button type="button" class="tylie-button tylie-button--xs tylie-button--icon ms-3" (click)="logoutClicked()" *ngIf="this.vaultAssetDetail.isInVault == true || this.vaultAssetDetail.isStagging == true">
      <span class="tylie-button__icon"><icon-export></icon-export></span>
      <span class="tylie-button__text">Log out</span>
    </button>
    <button type="button" class="tylie-button tylie-button--xs tylie-button--icon ms-3" (click)="removeClicked()" *ngIf="this.vaultAssetDetail.isInVault == true || this.vaultAssetDetail.isStagging == true">
      <span class="tylie-button__icon"><icon-delete></icon-delete></span>
      <span class="tylie-button__text">Remove</span>
    </button>
    <button type="button" class="tylie-button tylie-button--xs tylie-button--icon ms-3" (click)="destroyClicked()" *ngIf="this.vaultAssetDetail.isInVault == true || this.vaultAssetDetail.isStagging == true">
      <span class="tylie-button__icon"><icon-delete type="trash"></icon-delete></span>
      <span class="tylie-button__text">Destroy</span>
    </button>
    <button type="button" class="tylie-button tylie-button--xs tylie-button--icon ms-3" (click)="missingClicked()" *ngIf="this.vaultAssetDetail.isInVault == true">
      <span class="tylie-button__icon"><icon-question-mark></icon-question-mark></span>
      <span class="tylie-button__text">Missing</span>
    </button>
    <button type="button" class="tylie-button tylie-button--xs tylie-button--icon ms-3" (click)="editClicked()" *ngIf="this.vaultAssetDetail.isInVault == true || this.vaultAssetDetail.isStagging == true || this.vaultAssetDetail.isLoggedOut == true">
      <span class="tylie-button__icon"><icon-edit-pencil></icon-edit-pencil></span>
      <span class="tylie-button__text">Edit</span>
    </button>

    <button type="button" class="tylie-button tylie-button--xs tylie-button--icon ms-3" (click)="loginClicked()" *ngIf="this.vaultAssetDetail.isLoggedOut == true">
      <span class="tylie-button__icon"><icon-vault-login></icon-vault-login></span>
      <span class="tylie-button__text">Login</span>
    </button>
  </div>
</div>

<ng-container *ngIf="this.vaultAssetDetail && this.vaultAssetRequest">
  <form [formGroup]="vaultAssetRequest" #f="ngForm" (ngSubmit)="onSubmitVaultAsset(vaultAssetRequest)">
    <div class="d-flex mt-3">
      <div class="tylie-form-group form-group me-3">
        <label class="tylie-form-group__lbl">Warehouse</label>
        <div class="tylie-form-group__txt" *ngIf="this.IsEditable == false">{{this.vaultAssetDetail.cartonWarehouse}}</div>
        <div *ngIf="this.IsEditable == true" [ngClass]="{error: vaultAssetRequest.controls['cartonWarehouseId'].errors && f.submitted}">
          <dx-select-box [dataSource]="warehouseDataSource"
                          id="warehouseId"
                          name="warehouse"
                          class="tylie-select form-control"
                          formControlName="cartonWarehouseId"
                          [searchEnabled]="true"
                          valueExpr="id"
                          displayExpr="name"
                          placeholder="Select a warehouse...">
          </dx-select-box>
          <p class="p-extra-small">Please select Warehouse</p>
        </div>
      </div>
      <div class="tylie-form-group form-group me-3">
        <label class="tylie-form-group__lbl">Column</label>
        <div class="tylie-form-group__txt" *ngIf="this.IsEditable == false">{{this.vaultAssetDetail.cartonColumn}}</div>
        <div *ngIf="this.IsEditable == true" [ngClass]="{error: vaultAssetRequest.controls['cartonColumn'].errors && f.submitted}">
          <input type="text" id="cartonColumn" name="cartonColumn" class="tylie-text form-control" formControlName="cartonColumn">
          <p class="p-extra-small" *ngIf="vaultAssetRequest.controls['cartonColumn'].errors?.required">Please enter Column</p>
        </div>
      </div>
      <div class="tylie-form-group form-group me-3">
        <label class="tylie-form-group__lbl">Position</label>
        <div class="tylie-form-group__txt" *ngIf="this.IsEditable == false">{{this.vaultAssetDetail.cartonPosition}}</div>
        <div *ngIf="this.IsEditable == true" [ngClass]="{error: vaultAssetRequest.controls['cartonPosition'].errors && f.submitted}">
          <input type="text" id="cartonPosition" name="cartonPosition" class="tylie-text form-control" formControlName="cartonPosition">
          <p class="p-extra-small" *ngIf="vaultAssetRequest.controls['cartonPosition'].errors?.required">Please enter Position</p>
        </div>
      </div>
      <div class="tylie-form-group form-group me-3">
        <label class="tylie-form-group__lbl">Carton Barcode</label>
        <div class="tylie-form-group__txt" *ngIf="this.IsEditable == false">{{this.vaultAssetDetail.cartonBarcode}}</div>
        <div *ngIf="this.IsEditable == true" [ngClass]="{error: vaultAssetRequest.controls['cartonbarcode'].errors && f.submitted}">
          <input type="text" id="cartonbarcode" name="cartonbarcode" class="tylie-text form-control" formControlName="cartonbarcode">
          <p class="p-extra-small" *ngIf="vaultAssetRequest.controls['cartonbarcode'].errors?.required">Please enter Carton Barcode</p>
        </div>
      </div>
    </div>
      
    <div class="search-all-media all-orders" *ngIf="this.vaultAssetDetail.isLoggedOut == true">
      <div class="panel panel-default">
        <div class="panel-heading">
          <h4 class="panel-title" data-toggle="collapse" data-target="#vaultCollapseOne" aria-expanded="true">
            <a class="p-large">
              Current Asset Location
            </a>
          </h4>
        </div>
        <div id="vaultCollapseOne" class="panel-collapse collapse in">
          <div class="panel-body">
            <div class="row">
              <div class="col-md-12">
                <div class="p-normal order-header-details">
                  <div class="col-md-3">
                    <p class="black">
                      <b>
                        Date Logged Out
                      </b>
                    </p>
                    <div class="form-group" *ngIf="this.IsEditable == false">
                      <p class="black">{{this.vaultAssetDetail.assetlastloggedoutdate | usertimezone: 'MM/DD/YYYY'}}&nbsp;</p>
                    </div>
                    <div class="form-group" *ngIf="this.IsEditable == true" [ngClass]="{error: vaultAssetRequest.controls['assetlastloggedoutdate'].errors && f.submitted}">
                      <input type="date" id="assetlastloggedoutdate" name="assetlastloggedoutdate" class="form-control" formControlName="assetlastloggedoutdate">
                      <p class="p-extra-small" *ngIf="vaultAssetRequest.controls['assetlastloggedoutdate'].errors?.required">Please enter Last Logged out Date</p>
                    </div>
                  </div>
                  <div class="col-md-3">
                    <p class="black"><b>Expected Return</b></p>
                    <div class="form-group" *ngIf="this.IsEditable == false">
                      <p class="black">{{this.vaultAssetDetail.expectedreturndate | usertimezone: 'MM/DD/YYYY'}}&nbsp;</p>
                    </div>
                    <div class="form-group" *ngIf="this.IsEditable == true" [ngClass]="{error: vaultAssetRequest.controls['expectedreturndate'].errors && f.submitted}">
                      <input type="date" id="expectedreturndate" name="expectedreturndate" class="form-control" formControlName="expectedreturndate">
                      <p class="p-extra-small" *ngIf="vaultAssetRequest.controls['expectedreturndate'].errors?.required">Please enter Last Logged out Date</p>
                    </div>
                  </div>
                  <div class="col-md-3">
                    <p class="black"><b>Location</b></p>
                    <div class="form-group" *ngIf="this.IsEditable == false">
                      <p class="black">
                        Recipient Name: {{ this.vaultAssetDetail.location.recipientname}}<br />
                        Company Name: {{ this.vaultAssetDetail.location.companyname}}<br />
                        Address: {{ this.vaultAssetDetail.location.address}}<br />
                        City: {{ this.vaultAssetDetail.location.city}},
                        State: {{ this.vaultAssetDetail.location.state}},
                        Zip: {{ this.vaultAssetDetail.location.zip}}<br />
                        Contact Email: {{ this.vaultAssetDetail.location.contactemail}}<br />
                        Contact Phone: {{ this.vaultAssetDetail.location.contactphone}}
                        &nbsp;
                      </p>
                    </div>
                    <div class="form-group" *ngIf="this.IsEditable == true" [ngClass]="{error: vaultAssetRequest.controls['location'].errors && f.submitted}">
                      <input type="text" id="location" name="location" disabled class="form-control" formControlName="location">
                      <p class="p-extra-small" *ngIf="vaultAssetRequest.controls['location'].errors?.required">Please enter Location</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="tylie-accordion accordion">
      <div class="tylie-accordion__panel accordion-item">
        <h2 class="tylie-accordion__heading accordion-header">
          <button class="tylie-accordion__heading-btn accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#assetCollapseOne" aria-expanded="true" aria-controls="collapseOne">
            Asset Details
          </button>
        </h2>
        <div id="assetCollapseOne" class="tylie-accordion__content accordion-collapse collapse show">
          <div class="tylie-accordion__body accordion-body">
            <div class="tylie-accordion__body-content my-3">
              <div class="row">
                <div class="col-md-3">
                  <div class="tylie-form-group form-group">
                    <label class="tylie-form-group__lbl">Asset Format</label>
                    <div class="tylie-form-group__txt" *ngIf="this.IsEditable == false">{{this.vaultAssetDetail.format}}</div>
                    <div *ngIf="this.IsEditable == true" [ngClass]="{error: vaultAssetRequest.controls['formatId'].errors && f.submitted}">
                      <dx-select-box [dataSource]="formatDataSource"
                                      id="formatId"
                                      name="format"
                                      class=" tylie-select form-control"
                                      formControlName="formatId"
                                      [searchEnabled]="true"
                                      valueExpr="id"
                                      displayExpr="name"
                                      placeholder="Select a format...">
                      </dx-select-box>
                      <p class="p-extra-small">Please select Format</p>
                    </div>
                  </div>
                </div>
                <div class="col-md-3">
                  <div class="tylie-form-group form-group">
                    <label class="tylie-form-group__lbl">Asset Barcode</label>
                    <div class="tylie-form-group__txt" *ngIf="this.IsEditable == false">{{this.vaultAssetDetail.assetbarcode}}</div>
                    <div *ngIf="this.IsEditable == true" [ngClass]="{error: vaultAssetRequest.controls['assetbarcode'].errors && f.submitted}">
                      <input type="text" id="assetbarcode" name="assetbarcode" class="tylie-text form-control" formControlName="assetbarcode">
                      <p class="p-extra-small" *ngIf="vaultAssetRequest.controls['assetbarcode'].errors?.required">Please enter Asset Barcode</p>
                    </div>
                  </div>
                </div>
                <div class="col-md-3">
                  <div class="tylie-form-group form-group">
                    <label class="tylie-form-group__lbl">Asset Description</label>
                    <div class="tylie-form-group__txt" *ngIf="this.IsEditable == false">{{this.vaultAssetDetail.description}}</div>
                    <div *ngIf="this.IsEditable == true" [ngClass]="{error: vaultAssetRequest.controls['assetdescription'].errors && f.submitted}">
                      <input type="text" id="assetdescription" name="assetdescription" class="tylie-text form-control" formControlName="assetdescription">
                      <p class="p-extra-small" *ngIf="vaultAssetRequest.controls['assetdescription'].errors?.required">Please enter Asset Description By</p>
                    </div>
                  </div>
                </div>
                <div class="col-md-3">
                  <div class="tylie-form-group form-group">
                    <label class="tylie-form-group__lbl">Created</label>
                    <div class="tylie-form-group__txt" *ngIf="this.IsEditable == false">
                      {{this.vaultAssetDetail.createdby}}
                    </div>
                    <div *ngIf="this.IsEditable == true" [ngClass]="{error: vaultAssetRequest.controls['createdby'].errors && f.submitted}">
                      <input type="text" id="createdby" name="createdby" disabled class="tylie-text form-control" formControlName="createdby">
                      <p class="p-extra-small" *ngIf="vaultAssetRequest.controls['createdby'].errors?.required">Please enter Created By</p>
                    </div>
                  </div>
                </div>
                <div class="col-md-3">
                  <div class="tylie-form-group form-group">
                    <label class="tylie-form-group__lbl">Ingested</label>
                    <div class="tylie-form-group__txt" *ngIf="this.IsEditable == false">
                      {{this.vaultAssetDetail.assetingesteddate | usertimezone: 'MM/DD/YYYY'}}
                    </div>
                    <div *ngIf="this.IsEditable == true" [ngClass]="{error: vaultAssetRequest.controls['assetingesteddate'].errors && f.submitted}">
                      <!--<input type="date" id="assetingesteddate" name="assetingesteddate" class="form-control" formControlName="assetingesteddate">-->
                      <dx-date-box width="100%" type="date" id="assetingesteddate" name="assetingesteddate"
                                    displayFormat="MM/dd/yyyy" invalidDateMessage="Invalid date"
                                    placeholder="Select"
                                    class="tylie-datepicker form-control" [showClearButton]="true" formControlName="assetingesteddate"></dx-date-box>
                      <p class="p-extra-small" *ngIf="vaultAssetRequest.controls['assetingesteddate'].errors?.required">Please enter Ingested Date</p>
                    </div>
                  </div>
                </div>
                <div class="col-md-3">
                  <div class="tylie-form-group form-group">
                    <label class="tylie-form-group__lbl">Ingested By</label>
                    <div class="tylie-form-group__txt">{{this.vaultAssetDetail.assetingestedbyuserprofilename}}</div>
                    <!--<div class="form-group" *ngIf="this.IsEditable == true" [ngClass]="{error: vaultAssetRequest.controls['assetIngestedby'].errors && f.submitted}">
                      <input type="text" id="assetIngestedby" name="assetIngestedby" class="form-control" formControlName="assetIngestedby">
                      <p class="p-extra-small" *ngIf="vaultAssetRequest.controls['assetIngestedby'].errors?.required">Please enter Ingested By</p>
                    </div>-->
                  </div>
                </div>
                <div class="col-md-3">
                  <div class="tylie-form-group form-group">
                    <label class="tylie-form-group__lbl">Client</label>
                    <div class="tylie-form-group__txt" *ngIf="this.IsEditable == false">{{this.vaultAssetDetail.vaultRequestClient}}</div>
                    <div *ngIf="this.IsEditable == true" [ngClass]="{error: vaultAssetRequest.controls['clientId'].errors && f.submitted}">
                      <dx-select-box [dataSource]="clientDataSource"
                                      id="client"
                                      name="client"
                                      class="tylie-select form-control"
                                      formControlName="clientId"
                                      [searchEnabled]="true"
                                      valueExpr="id"
                                      displayExpr="name"
                                      [disabled]="true"
                                      placeholder="Select a client...">
                      </dx-select-box>
                      <p class="p-extra-small">Please select Client</p>
                    </div>
                  </div>
                </div>
                <div class="col-md-3">
                  <div class="tylie-form-group form-group">
                    <label class="tylie-form-group__lbl">Brand</label>
                    <div class="tylie-form-group__txt" *ngIf="this.IsEditable == false">
                      {{this.vaultAssetDetail.vaultRequestBrand}}
                    </div>
                    <div *ngIf="this.IsEditable == true" [ngClass]="{error: vaultAssetRequest.controls['brandId'].errors && f.submitted}">
                      <dx-select-box [dataSource]="brandDataSource"
                                      class="tylie-select form-control"
                                      id="brand"
                                      name="brand"
                                      formControlName="brandId"
                                      [searchEnabled]="true"
                                      valueExpr="id"
                                      displayExpr="name"
                                      noDataText="Please choose a client"
                                      [disabled]="true"
                                      placeholder="Select a brand...">
                      </dx-select-box>
                      <p class="p-extra-small">Please select Brand</p>
                    </div>
                  </div>
                </div>
                <div class="col-md-3">
                  <div class="tylie-form-group form-group">
                    <label class="tylie-form-group__lbl">Product</label>
                    <div class="tylie-form-group__txt" *ngIf="this.IsEditable == false">
                      {{this.vaultAssetDetail.vaultRequestProduct}}
                    </div>
                    <div *ngIf="this.IsEditable == true" [ngClass]="{error: vaultAssetRequest.controls['productId'].errors && f.submitted}">
                      <dx-select-box [dataSource]="productDataSource"
                                      class="tylie-select form-control"
                                      id="product"
                                      name="product"
                                      [searchEnabled]="true"
                                      noDataText="Please choose a brand"
                                      formControlName="productId"
                                      valueExpr="id"
                                      displayExpr="name"
                                      placeholder="Select a product..."
                                      [disabled]="true">
                      </dx-select-box>
                      <p class="p-extra-small">Please select Product</p>
                    </div>
                  </div>
                </div>
                <div class="col-md-3">
                  <div class="tylie-form-group form-group">
                    <label class="tylie-form-group__lbl">Requested By</label>
                    <div class="tylie-form-group__txt" *ngIf="this.IsEditable == false">
                      {{this.vaultAssetDetail.vaultRequestRequestedBy}}
                    </div>
                    <div *ngIf="this.IsEditable == true" [ngClass]="{error: vaultAssetRequest.controls['requestedby'].errors && f.submitted}">
                      <input type="text" id="requestedby" name="requestedby" disabled class="tylie-text form-control" formControlName="requestedby">
                      <p class="p-extra-small" *ngIf="vaultAssetRequest.controls['requestedby'].errors?.required">Please enter Requested By</p>
                    </div>
                  </div>
                </div>
                <div class="col-md-3">
                  <div class="tylie-form-group form-group">
                    <label class="tylie-form-group__lbl">Client Job Number</label>
                    <div class="tylie-form-group__txt" *ngIf="this.IsEditable == false">
                      {{this.vaultAssetDetail.vaultRequestClientJobNumber}}
                    </div>
                    <div *ngIf="this.IsEditable == true" [ngClass]="{error: vaultAssetRequest.controls['clientjobnumber'].errors && f.submitted}">
                      <input type="text" id="clientjobnumber" name="clientjobnumber" disabled class="tylie-text form-control" formControlName="clientjobnumber">
                      <p class="p-extra-small" *ngIf="vaultAssetRequest.controls['clientjobnumber'].errors?.required">Please enter Client Job Number</p>
                    </div>
                  </div>
                </div>
                <div class="col-md-3">
                  <div class="tylie-form-group form-group">
                    <label class="tylie-form-group__lbl">Reference</label>
                    <div class="tylie-form-group__txt" *ngIf="this.IsEditable == false">
                      {{this.vaultAssetDetail.vaultRequestReference}}
                    </div>
                    <div *ngIf="this.IsEditable == true" [ngClass]="{error: vaultAssetRequest.controls['reference'].errors && f.submitted}">
                      <input type="text" id="reference" name="reference" disabled class="tylie-text form-control" formControlName="reference">
                      <p class="p-extra-small" *ngIf="vaultAssetRequest.controls['reference'].errors?.required">Please enter Reference</p>
                    </div>
                  </div>
                </div>

                <div class="col-md-3" *ngIf="this.vaultAssetDetail.bjvNumber">
                  <div class="tylie-form-group form-group">
                    <label class="tylie-form-group__lbl">BJV#</label>
                    <div class="tylie-form-group__txt">
                      {{this.vaultAssetDetail.bjvNumber}}
                    </div>
                  </div>
                </div>

                <div class="col-md-3" *ngIf="this.vaultAssetDetail.legacyVaultLocation">
                  <div class="tylie-form-group form-group">
                    <label class="tylie-form-group__lbl">Legacy Vault Location</label>
                    <div class="tylie-form-group__txt">
                      {{this.vaultAssetDetail.legacyVaultLocation}}
                    </div>
                  </div>
                </div>

                <div class="col-md-3">
                  <div class="tylie-form-group form-group">
                    <label class="tylie-form-group__lbl">Received From</label>
                    <div class="tylie-form-group__txt" *ngIf="this.IsEditable == false">
                      {{this.vaultAssetDetail.vaultRequestReceivedFrom}}
                    </div>
                    <div *ngIf="this.IsEditable == true" [ngClass]="{error: vaultAssetRequest.controls['receivedfrom'].errors && f.submitted}">
                      <input type="text" id="receivedfrom" name="receivedfrom" disabled class="tylie-text form-control" formControlName="receivedfrom">
                      <p class="p-extra-small" *ngIf="vaultAssetRequest.controls['receivedfrom'].errors?.required">Please enter Received From</p>
                    </div>
                  </div>
                </div>
              </div>
            
              <div class="tylie-panel tylie-panel--secondary">
                <div class="tylie-panel__header tylie-panel__header--br-thick">
                  <h4 class="tylie-panel__title">
                    ASSET CONTENTS
                  </h4>
                </div>
                <div class="tylie-panel__body" *ngIf="this.assetlineitems?.length === 0 && this.IsEditable == false">There are no Asset Lineitems yet.</div>
                <div class="tylie-panel__body">
                  <div class="tylie-table-wrapper">
                    <table class="tylie-table table table-bordered" *ngIf="this.assetlineitems.length > 0 && this.IsEditable == false">
                      <thead class="tylie-table__head">
                        <tr>
                          <th width="10%">Seq.</th>
                          <th width="20%">Asset-ID</th>
                          <th width="20%">Length</th>
                          <th width="50%">Title</th>
                        </tr>
                      </thead>
                      <tbody class="tylie-table__body">
                        <tr *ngFor="let att of this.assetlineitems; let i = index">
                          <td>{{i+1}}</td>
                          <td>{{att.adid}}</td>
                          <td>{{att.length}}</td>
                          <td>{{att.title}}</td>
                        </tr>
                      </tbody>
                    </table>
                    <table class="tylie-table table" *ngIf="this.IsEditable == true">
                      <thead class="tylie-table__head">
                        <tr>
                          <th style="width:5%;">Seq.</th>
                          <th style="width:22%;">Asset-ID</th>
                          <th style="width:22%;">Length</th>
                          <th style="width:46%;">Title</th>
                          <th style="width:5%;">Actions</th>
                        </tr>
                      </thead>
                      <tbody class="tylie-table__body">
                        <ng-container *ngFor="let att of this.vaultAssetRequest.controls['assetLineItemData']?.controls; let j = index">
                          <ng-container [formGroup]="att">
                            <tr>
                              <td style="width:5%;" class="tyl-field-underline1">{{j+1}}</td>
                              <td style="width:22%;" class="tyl-field-underline1">
                                <div class="form-group" [ngClass]="{error: att.controls['adId'].errors && f.submitted }">
                                  <input type="text" name="adId" class="form-control" formControlName="adId">
                                  <p class="p-extra-small" *ngIf="att.controls['adId'].errors">Please enter a valid Ad-ID</p>
                                </div>
                              </td>
                              <td style="width:22%;" class="tyl-field-underline1">
                                <div class="form-group" [ngClass]="{error: att.controls['length'].errors && f.submitted }">
                                  <input type="text" name="length" class="form-control" formControlName="length">
                                  <p class="p-extra-small" *ngIf="att.controls['length'].errors">Please enter a valid Length (Ex: 60, 3:45, 1:20:30)</p>
                                </div>
                              </td>
                              <td style="width:46%;" class="tyl-field-underline1">
                                <div class="form-group" [ngClass]="{error: att.controls['title'].errors && f.submitted }">
                                  <input type="text" name="title" class="form-control" formControlName="title">
                                  <p class="p-extra-small" *ngIf="att.controls['title'].errors">Please enter a valid Title</p>
                                </div>
                              </td>
                              <td style="width:5%;" align="center">
                                <i class="glyphicon glyphicon-remove-sign tyl-input-delete-vertical-align" (click)="onDeleteAssetLineItem(j)"></i>
                              </td>
                            </tr>
                          </ng-container>
                        </ng-container>
                      </tbody>
                    </table>
                    
                  </div>
                  <div class="" *ngIf="this.IsEditable == true">
                    <button  type="button" class="tylie-button tylie-button--xs tylie-button--icon" (click)="onAddNewAssetLine()">
                      <span class="tylie-button__icon">
                        <icon-plus-circle></icon-plus-circle>
                      </span>
                      <span class="tylie-button__text">ADD MORE LINES</span>
                    </button>
                  </div>
                </div>
              </div>

              <div class="row">
                <div class="col-md-6">
                  <div class="tylie-panel tylie-panel--secondary">
                    <div class="tylie-panel__header tylie-panel__header--br-thick">
                      <h4 class="tylie-panel__title">
                        NOTES & HISTORY
                        <div *ngIf="this.IsEditable == false">
                          <button type="button" class="tylie-button tylie-button--xs tylie-button--icon" (click)="onAddNewNote()">
                            <span class="tylie-button__icon">
                              <icon-plus-circle></icon-plus-circle>
                            </span>
                            <span class="tylie-button__text">ADD Note</span>
                          </button>
                        </div>
                      </h4>
                    </div>
                    <div class="tylie-panel__body" *ngIf="this.notes.length == 0">There are no notes and history yet.</div>
                    <div class="tylie-panel__body">
                      <div class="tylie-table-wrapper">
                        <table class="tylie-table table table-bordered" *ngIf="this.notes.length > 0">
                          <tbody class="tylie-table__body">
                            <tr *ngFor="let att of this.notes; let i = index">
                              <td><div class="p-normal">{{att.message}}</div> <span class="p-tiny">by {{att.createdBy}}</span>&nbsp;&nbsp;&nbsp;<span class="p-tiny">{{att.createdDate | usertimezone: 'MM/DD/YYYY HH:mm A z'}}</span></td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                </div>

                <div class="col-md-6">
                  <div class="tylie-panel tylie-panel--secondary">
                    <div class="tylie-panel__header tylie-panel__header--br-thick">
                      <h4 class="tylie-panel__title">
                        ATTACHMENTS
                        <div *ngIf="this.IsEditable == false">
                          <button type="button" class="tylie-button tylie-button--xs tylie-button--icon" (click)="onAddNewAttachment()">
                            <span class="tylie-button__icon">
                              <icon-plus-circle></icon-plus-circle>
                            </span>
                            <span class="tylie-button__text">ADD ATTACHMENTS</span>
                          </button>
                        </div>
                      </h4>
                    </div>
                    
                    <div class="tylie-panel__body" *ngIf="this.attachments.length == 0">
                      There are no attachments yet.
                    </div>
                    <div class="tylie-panel__body">
                      <div class="tylie-table-wrapper">
                        <table class="tylie-table table table-bordered" *ngIf="this.attachments?.length > 0">
                          <tbody class="tylie-table__body">
                            <tr *ngFor="let att of this.attachments; let i = index">
                              <td class="blue-text" (click)="onDownloadFile(att.vaultFileGuid, att.fileName)">{{att.fileName}}</td>
                              <td align="center">
                                <i class="tylie-button tylie-button--xs tylie-button--link" outsideClick="true" placement="left" #pop="bs-popover" container="body" [popoverContext]="att" [popover]="deleteAttTemplate">
                                  <icon-trash-can color="#1e90ff"></icon-trash-can>
                                </i>
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                </div>

                <div class="col-md-6">
                  <div class="tylie-panel tylie-panel--secondary">
                    <div class="tylie-panel__header tylie-panel__header--br-thick">
                      <h4 class="tylie-panel__title">
                        ADDITIONAL SERVICES
                        <div *ngIf="this.IsEditable == false">
                          <button type="button" class="tylie-button tylie-button--xs tylie-button--icon" (click)="onAddNewService()">
                            <span class="tylie-button__icon">
                              <icon-plus-circle></icon-plus-circle>
                            </span>
                            <span class="tylie-button__text">ADD SERVICE</span>
                          </button>
                        </div>
                      </h4>
                    </div>
                    <div class="tylie-panel__body" *ngIf="this.additionServices?.length == 0">There are no additional services.</div>
                    <div class="tylie-panel__body">
                      <div class="tylie-table-wrapper">
                        <table class="tylie-table table table-bordered" *ngIf="this.additionServices?.length > 0">
                          <tbody class="tylie-table__body">
                            <tr *ngFor="let att of this.additionServices; let i = index">
                              <td>{{att.serviceName}}</td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="d-flex" *ngIf="this.IsEditable == true">
        <button type="submit" class="tylie-button tylie-button--xs tylie-button--icon">
          <span class="tylie-button__icon"><icon-save></icon-save></span>
          <span class="tylie-button__txt">Update Vault Asset</span>
        </button>

        <button type="button" class="tylie-button tylie-button--xs tylie-button--link ms-3" outsideClick="true" placement="top" #clearVaultpop="bs-popover" container="body" [popover]="cancelVaultTemplate">
          Cancel
        </button>
    </div>
  </form>
</ng-container>

<ng-template #deleteAttTemplate let-fileName="fileName" let-vaultFileGuid="vaultFileGuid">
  <!-- <div class='p-normal'>
    <p class='black'>
      Are you sure you'd like to delete {{fileName}}?
    </p>
    <i (click)="onHidePop()"></i>
  </div>
  <button class='btn tyl-btn btn-extra-small' (click)="onAttDelete(fileName, vaultFileGuid)">Delete</button> -->


  <button type="button" class="popover__close" (click)="onHidePop()">
    <icon-delete color="#000" height="14px" width="14px"></icon-delete>
  </button>
  <header class="popover__header">Are you sure you'd like to delete {{fileName}}?</header>
  <button type="button" class='tylie-button tylie-button--xs' (click)="onAttDelete(fileName, vaultFileGuid)">Delete</button>
</ng-template>

<ng-template #cancelVaultTemplate>
  <!-- <div class='p-normal'>
    <p class='black'>
      Are you sure you'd like to cancel?
    </p>
    <i (click)="onHideClearVaultPop()"></i>
  </div>
  <button class='btn tyl-btn btn-extra-small' (click)="clearSavedData()">Yes</button>
 -->

  <button type="button" class="popover__close" (click)="onHideClearVaultPop()">
    <icon-delete color="#000" height="14px" width="14px"></icon-delete>
  </button>
  <header class="popover__header">Are you sure you'd like to cancel?</header>
  <button type="button" class='tylie-button tylie-button--xs' (click)="clearSavedData()">Yes</button>
</ng-template>


