/**
 * This file contains authentication parameters. Contents of this file
 * is roughly the same across other MSAL.js libraries. These parameters
 * are used to initialize Angular and MSAL Angular configurations in
 * in app.module.ts file.
 */

import {
  LogLevel,
  Configuration,
  BrowserCacheLocation,
} from '@azure/msal-browser';
import { environment } from '../environments/environment';

/**
 * Configuration object to be passed to MSAL instance on creation.
 * For a full list of MSAL.js configuration parameters, visit:
 * https://github.com/AzureAD/microsoft-authentication-library-for-js/blob/dev/lib/msal-browser/docs/configuration.md
 *
 */

const devConfig: Configuration = {
  auth: {
    clientId: 'e1fe6b16-12cc-42a0-b41e-cdeeff20ad7b', 
    authority: 'https://TrialTenantrLo8Rute.ciamlogin.com/TrialTenantrLo8Rute.onmicrosoft.com',
    redirectUri: 'http://localhost:4200/profileselection', 
    postLogoutRedirectUri: 'https://7milesglobal.com/',
    navigateToLoginRequestUrl: false,
    knownAuthorities: ['TrialTenantrLo8Rute.ciamlogin.com']
  },
  cache: {
    cacheLocation: BrowserCacheLocation.SessionStorage,
  },
  system: {
    loggerOptions: {
      loggerCallback(logLevel: LogLevel, message: string) {
        console.log(message);
      },
      logLevel: LogLevel.Verbose,
      piiLoggingEnabled: true
    },
  },
};


const stagingConfig: Configuration = {
  auth: {
    clientId: 'e1fe6b16-12cc-42a0-b41e-cdeeff20ad7b',
    authority: 'https://TrialTenantrLo8Rute.ciamlogin.com/TrialTenantrLo8Rute.onmicrosoft.com',
    redirectUri: 'https://tandem.tyliedev.com/profileselection', 
    postLogoutRedirectUri: 'https://7milesglobal.com/',
    navigateToLoginRequestUrl: false,
    knownAuthorities: ['TrialTenantrLo8Rute.ciamlogin.com']
  },
  cache: {
    cacheLocation: BrowserCacheLocation.SessionStorage, 
  },
  system: {
    loggerOptions: {
      loggerCallback(logLevel: LogLevel, message: string) {
        console.log(message);
      },
      logLevel: LogLevel.Verbose,
      piiLoggingEnabled: true
    },
  },
};

const prodConfig: Configuration = {
  auth: {
    clientId: '401c9244-41a6-47bc-9c88-6c755f096187', // This is the ONLY mandatory field that you need to supply.
    authority: 'https://login.microsoftonline.com/7milesglobal.com', // Replace the placeholder with your tenant subdomain
    redirectUri: 'https://tandem.tylie.com/profileselection', // Points to window.location.origin by default. You must register this URI on Microsoft Entra admin center/App Registration.
    postLogoutRedirectUri: 'https://7milesglobal.com/',// Points to window.location.origin by default
    navigateToLoginRequestUrl: false
  },
  cache: {
    cacheLocation: BrowserCacheLocation.SessionStorage, // Configures cache location. "sessionStorage" is more secure, but "localStorage" gives you SSO between tabs.
  },
  system: {
    loggerOptions: {
      loggerCallback(logLevel: LogLevel, message: string) {
        console.log(message);
      },
      logLevel: LogLevel.Verbose,
      piiLoggingEnabled: true
    },
  },
};



export const msalConfig: Configuration = (() => {
  if (environment.production) {
    return prodConfig;
  } else if (environment.staging) {
    return stagingConfig;
  } else {
    return devConfig;
  }
})();


/**
 * Scopes you add here will be prompted for user consent during sign-in.
 * By default, MSAL.js will add OIDC scopes (openid, profile, email) to any login request.
 * For more information about OIDC scopes, visit:
 * https://docs.microsoft.com/en-us/azure/active-directory/develop/v2-permissions-and-consent#openid-connect-scopes
 */
export const loginRequest = {
  scopes: [],
};
